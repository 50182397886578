import React, { useEffect } from 'react';
import ErrorPage from '../_error';
import Page from '../../components/Pages/Page';
import { getApolloClientInstance } from '../../server/ssrapollo';
import {
  query404Page,
  queryAnchorLinkItems,
  queryBlockAccordions,
  queryBlocks,
  queryLayout,
  queryPage
} from '../../server/ssrqueries';
import { GetServerSidePropsContext } from 'next';
import {
  ServerSideRenderingDataContext,
  ServerSideRenderingDataInterface,
  useServerSideRenderingDataContext
} from '../../components/ServerSideRenderingDataContext';
import { ssrQueriesDispatch } from '../../server/ssrQueries/dispatch';
import { getNavigationForPage } from '../../server/navigation';
import { useContentfulLiveUpdates } from '@contentful/live-preview/react';
import { commonVariables } from '../../server/ssrQueries/helpers';
import { usePageTrackingContext } from '../../lib/context/PageTrackingContext';
import {
  GTMVirtualPageViewContentSource,
  GTMVirtualPageViewPageType,
  GTMVirtualPageViewSubType1
} from '../../helpers/useGaTracking';

const EachPage = () => {
  const dataContext = useServerSideRenderingDataContext();
  const { data, errors, loading } = dataContext.PAGE_QUERY;
  const isPreview = commonVariables().preview;
  const { updatePageContext } = usePageTrackingContext();

  if (loading) return null;
  if (errors) return <ErrorPage statusCode={500} error={errors} />;
  if (!data?.pageCollection?.items?.length)
    return <ErrorPage statusCode={404} />;

  const page = isPreview
    ? useContentfulLiveUpdates(data.pageCollection.items[0])
    : data.pageCollection.items[0];

  useEffect(() => {
    updatePageContext({
      title: page.title,
      subType1: isPreview
        ? GTMVirtualPageViewSubType1.Preview
        : GTMVirtualPageViewSubType1.Detail,
      pageType: GTMVirtualPageViewPageType.Page,
      contentId: page.sys.id,
      contentSourceSystemId: GTMVirtualPageViewContentSource.Contentful
    });
  }, []);

  return <Page {...page} />;
};

const ContextEachPage = (props: {
  queryResults: ServerSideRenderingDataInterface;
}) => {
  return (
    <ServerSideRenderingDataContext.Provider value={props.queryResults}>
      <EachPage />
    </ServerSideRenderingDataContext.Provider>
  );
};

export async function getServerSideProps(
  context: GetServerSidePropsContext
): Promise<{ props: { queryResults: ServerSideRenderingDataInterface } }> {
  const { pid } = context.query;
  const apolloClient = getApolloClientInstance();

  const ssrQueryMatrix = {
    layoutProps: { action: queryLayout(apolloClient), param: null },
    fourZeroFourProps: { action: query404Page(apolloClient), param: null },
    fourZeroFourBlocksProps: {
      action: queryBlocks(apolloClient),
      param: (fourZeroFourProps) =>
        fourZeroFourProps?.data?.pageCollection?.items?.[0]?.blocksCollection
          ?.items ?? []
    },
    pageProps: {
      action: queryPage(pid as string, apolloClient),
      param: null
    },
    pageBlocksProps: {
      action: queryBlocks(apolloClient),
      param: (pageProps) =>
        pageProps?.data?.pageCollection?.items?.[0]?.blocksCollection?.items ??
        []
    },
    pageAnchorLinksContent: {
      action: queryBlocks(apolloClient),
      param: (pageBlocksProps) =>
        pageBlocksProps
          ?.filter(
            (block) => block.data?.block.__typename === 'BlockAnchorLinks'
          )
          .map((block) => block.data.block?.contentCollection?.items)
          .reduce((acc, cur) => acc.concat(cur), []) // manual flatMap
    },
    pageAnchorLinksItems: {
      action: queryAnchorLinkItems(apolloClient),
      param: (pageBlocksProps) =>
        pageBlocksProps
          ?.filter(
            (block) => block.data?.block.__typename === 'BlockAnchorLinks'
          )
          .map((block) => block.data.block?.itemsCollection?.items)
          .reduce((acc, cur) => acc.concat(cur), []) // manual flatMap
    },
    pageBlockAccordions: {
      action: queryBlockAccordions(apolloClient),
      param: (pageBlocksProps) =>
        pageBlocksProps
          ?.filter(
            (block) => block.data?.block.__typename === 'BlockAccordions'
          )
          .map((block) => block.data.block.accordionsCollection.items)
          .reduce((acc, cur) => acc.concat(cur), []) // manual flatMap
    },
    pageAnchorLinkAccordions: {
      action: queryBlockAccordions(apolloClient),
      param: (pageAnchorLinksItems) =>
        pageAnchorLinksItems
          ?.filter(
            (anchorLink) =>
              anchorLink.data?.block.__typename === 'BlockAccordions'
          )
          .map(
            (anchorLink) => anchorLink.data?.block.accordionsCollection.items
          )
          .reduce((acc, cur) => acc.concat(cur), []) // manual flatMap
    }
  };

  const props = await ssrQueriesDispatch(ssrQueryMatrix);

  //Based on the page data received get the corresponding navigation module for that page.
  const pageNavigationModuleId =
    props['pageProps'].data?.pageCollection.items[0]?.navigationModule?.sys
      .id || '';

  const navigationModuleData = await getNavigationForPage(
    apolloClient,
    props['pageProps'].data.pageCollection.items[0]?.sys.id || '',
    pageNavigationModuleId
  );

  return {
    props: {
      queryResults: {
        PAGE_LAYOUT: props['layoutProps'],
        PAGE_NAVIGATION_MODULE_DATA: navigationModuleData,
        PAGE_404: props['fourZeroFourProps'],
        PAGE_QUERY: props['pageProps'],
        PAGE_BLOCKS: [
          ...props['fourZeroFourBlocksProps'],
          ...props['pageBlocksProps'],
          ...props['pageAnchorLinksContent'],
          ...props['pageAnchorLinksItems']
        ],
        BLOCK_ACCORDIONS: [
          ...props['pageBlockAccordions'],
          ...props['pageAnchorLinkAccordions']
        ]
      }
    }
  };
}

export default ContextEachPage;
